import React, { ReactNode } from 'react';
import { graphql } from 'gatsby';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import ContentfulRichText from '../components/contentful-rich-text';
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../components/header';
import '../styles/contentfulSection.css'
import Footer from '../components/footer';

interface IContantfulAsset {
  gatsbyImageData: IGatsbyImageData
}

type Section = {
    id: string
    title: string
    slug: string
    updatedAt: string
    heroImage?: IContantfulAsset
    content: RenderRichTextData<ContentfulRichTextGatsbyReference>
}

type SectionData = {
  data: {
    contentfulSection: Section
  }
}

const Page = ({ data }: SectionData) => {
  const lastUpdatedDate = new Date(data.contentfulSection.updatedAt);
  const lastUpdatedFormattedDate = lastUpdatedDate.toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  console.log(data.contentfulSection.heroImage);

  return (
    <Container fluid className="px-0 d-flex flex-column min-vh-100">
      <Container fluid className="site-header-container">
        <Header />
      </Container>
      <main>
        <Container fluid className="section-header">
          <Row className={"py-2"}>
            <Col />
            <Col className="col-contentful-section">
              {data.contentfulSection.heroImage &&
                <Container className='section-hero'>
                  <GatsbyImage alt={'Hero image'} image={data.contentfulSection.heroImage.gatsbyImageData} />
                </Container>
              }
              <h1 className="my-3">{data.contentfulSection?.title}</h1>
              <hr className='line' />
            </Col>
            <Col />
          </Row>
        </Container>

        <Container fluid>
          <Row className={"py-4"}>
            <Col />
            <Col className="col-contentful-section">
              <ContentfulRichText content={data.contentfulSection.content} />
              <div className='last-updated py-3'>Last updated: <b>{lastUpdatedFormattedDate}</b></div>
            </Col>
            <Col />
          </Row>
        </Container>
      </main>
      <Footer />
    </Container>
  );
};

export const data = graphql`
  query pageQuery($id: String) {
    contentfulSection(id: { eq: $id }) {
      slug
      title
      updatedAt
      heroImage {
        __typename
        contentful_id
        filesize
        filename
        description
        file {
          url
          contentType
        }
        gatsbyImageData(width: 100)
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            file {
              url
              contentType
            }
            title
            description
            gatsbyImageData(width: 1000)
          }
          ... on ContentfulSection {
            __typename
            contentful_id
            title
            slug
          }
        }
      }
      sys {
        __typename
        type
        contentType {
          sys {
            id
            linkType
            type
          }
        }
      }
    }
  }
`;

export default Page;