import React from 'react'
import PropTypes from 'prop-types'
import * as styles from '../styles/contentful-rich-text.module.css'

import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types'
import { ContentfulRichTextGatsbyReference, renderRichText, RenderRichTextData } from 'gatsby-source-contentful/rich-text'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { Options } from "@contentful/rich-text-react-renderer"
import { Link } from 'gatsby'

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
    [MARKS.ITALIC]: (text) => <i className="font-italic">{text}</i>,
    [MARKS.UNDERLINE]: (text) => <u className="underline">{text}</u>,
    [MARKS.CODE]: (text) => (
      <div className={styles.code}>
        <code className={"font-mono px-1 py-2 mx-1 bg-gray-100 rounded text-sm"}>
          {text}
        </code>
      </div>
    ),
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <a
        href={node.data.uri}
        target="_blank"
        rel="noreferrer"
        className="text-brand-default underline"
      >
        {children}
      </a>
    ),
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const nodeContent = node.content[0]
      const value = nodeContent?.nodeType == 'text' && nodeContent.value
      const { slug } = node.data.target

      return (
        <Link to={'/' + slug + '/'}>{value}</Link>
      );
    },
    [BLOCKS.HEADING_1]: (node, children) => (
      <h2 className={styles.heading1 + " text-3xl sm:text-4xl text-left font-black text-gray-700 leading-tight mb-2"}>
        {children}
      </h2>
    ),
    [BLOCKS.HEADING_2]: (node, children) => {
      return (
        <h2 className={styles.heading2 + " text-3xl sm:text-4xl text-left font-black text-gray-700 leading-tight mb-2"}>
          {children}
        </h2>
      )
    },
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className={styles.heading3 + " text-2xl sm:text-3xl text-left font-black text-gray-700 leading-tight mb-2"}>
        {children}
      </h3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <h4 className={styles.heading4 + " text-xl sm:text-2xl text-left font-black text-gray-700 leading-tight mb-2"}>
        {children}
      </h4>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol className={styles.olList + " list-decimal pl-4"}>{children}</ol>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className={styles.ulList + " list-disc pl-4"}>{children}</ul>
    ),

    [BLOCKS.LIST_ITEM]: (node, children) => (
      <li className={styles.listItem + " mb-1"}>{children}</li>
    ),
    [BLOCKS.QUOTE]: (node, children) => {
      return (
        <blockquote className={styles.quote}>
          <p>{children}</p>
        </blockquote>
      );
    },
    [BLOCKS.HR]: () => <hr className="mb-6" />,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { gatsbyImageData, description } = node.data.target
      const image = getImage(gatsbyImageData)
      return (
        image && <GatsbyImage
          image={image}
          alt={description}
          className="mb-10"
        />
      )
    }
  },
} as Options;

type ContentfulRichTextProps = {
  content: RenderRichTextData<ContentfulRichTextGatsbyReference>
}

const ContentfulRichText = (props: ContentfulRichTextProps) => {
  return <div>{renderRichText(props.content, options)}</div>
}

export default ContentfulRichText